<template>
  <div class="user-info-wrap">
    <div class="user-info">
      <img class="user-avatar" :src="userInfo.avator?userInfo.avator: require('../../images/default_avatar.png')"/>
      <div class="user-info-txt">
        <span class="username">{{userInfo.nickName}}</span>
        <span v-if="userInfo.referralCode">邀请码：{{userInfo.referralCode}}</span>
      </div>
      <img class="setting-btn" @click="$router.push('/setting')" src="../../images/setting_icon.png"/>
    </div>
  </div>
  <div class="my-cnt">
    <div class="wrap order-wrap">
      <div class="title">
        <h4>我的订单</h4>
        <span @click="$router.push({ name: 'OrderList', params: { status: '' }})">全部订单</span>
      </div>
      <ul>
          <li @click="$router.push({ name: 'OrderList', params: { status: '6' }})">
            <img src="../../images/order-icon01.png"/>
            <span>待支付</span>
            <span class="order-num" v-if="bePay>0">{{bePay}}</span>
          </li>
          <li @click="$router.push({ name: 'OrderList', params: { status: '1' }})">
            <img src="../../images/order-icon02.png"/>
            <span>待配货</span>
            <span class="order-num" v-if="beDistribution>0">{{beDistribution}}</span>
          </li>
          <li @click="$router.push({ name: 'OrderList', params: { status: '2,9' }})">
            <img src="../../images/order-icon03.png"/>
            <span>待收货</span>
            <span class="order-num" v-if="beForThe>0">{{beForThe}}</span>
          </li>
          <!-- <li @click="$router.push({ name: 'OrderList', params: { status: '3' }})">
            <img src="../../images/order-icon04.png"/>
            <span>待评价</span>
            <span class="order-num" v-if="beEvaluation>0">{{beEvaluation}}</span>
          </li>
          <li @click="$router.push({ name: 'OrderList', params: { status: '7,8,14' }})">
            <img src="../../images/order-icon05.png"/>
            <span>退货</span>
            <span class="order-num" v-if="beRefund>0">{{beRefund}}</span>
          </li> -->
      </ul>
    </div>
    <div class="wrap service-wrap">
      <div class="title">
        <h4>我的服务</h4>
      </div>
      <ul>
        <li @click="$router.push('/mygroup')">
            <img src="../../images/service-icon01.png"/>
            <span>我的拼团</span>
        </li>
        <li @click="$router.push('/address')">
            <img src="../../images/service-icon02.png"/>
            <span>地址管理</span>
        </li>
        <li @click="toCoupons(0)">
            <img src="../../images/service-icon03.png"/>
            <span>领券中心</span>
        </li>
        <li @click="toCoupons(1)">
            <img src="../../images/service-icon04.png"/>
            <span>我的优惠券</span>
        </li>
        <li @click="$router.push('/help')">
            <img src="../../images/service-icon05.png"/>
            <span>帮助中心</span>
        </li>
        <li @click="$router.push('/feedback')">
            <img src="../../images/service-icon06.png"/>
            <span>意见反馈</span>
        </li>
        <a class="li" :href="servicePhone">
            <img src="../../images/service-icon07.png"/>
            <span>联系客服</span>
        </a>
        <!-- <li>
            <img src="../../images/service-icon08.png"/>
            <span>推荐码</span>
        </li> -->
        <li @click="$router.push('/collection')">
            <img src="../../images/service-icon10.png"/>
            <span>我的收藏</span>
        </li>
        <li @click="$router.push('/setting')">
            <img src="../../images/service-icon09.png"/>
            <span>设置</span>
        </li>
      </ul>
    </div>
  </div>
  <Tabbar :carNumNew="carNumNew"/>
</template>
<script>
import qs from 'qs'
import { post, ajaxParam } from '../../units/request.js'
import Tabbar from '../home/Tabbar.vue'
export default {
  data () {
    return {
      beConfirm: '', // 待确认
      beEvaluation: '', // 待评价
      beForThe: '', // 待收货
      beDistribution: '', // 待配货
      bePay: '', // 待支付
      beSend: '', // 待发货
      beCancel: '', // 已取消
      beRefund: '', // 退货
      servicePhone: '' // 客服电话
    }
  },
  name: 'My',
  components: {
    Tabbar
  },
  methods: {
    async getOrderCount () {
      const url = 'v2/orderInfo/client/orderCount.json'
      const param = {
        userId: localStorage.userId
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      const orderNum = res.data.entity
      this.bePay = orderNum.bePay
      this.beDistribution = orderNum.beDistribution
      this.beForThe = orderNum.beForThe
      this.beEvaluation = orderNum.beEvaluation
      this.beRefund = orderNum.beRefund
    },
    async getservicePhone () {
      const url = 'v1/configApp/contactPhone.json'
      const param = {}
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      this.servicePhone = res.data.contactPhone
      this.servicePhone = 'tel:' + this.servicePhone
    },
    toCoupons (type) {
      this.$router.push({
        name: 'Coupons',
        query: {
          type: type
        }
      })
    }
  },
  created () {
    this.carNumNew = localStorage.carNumNew
    this.userName = localStorage.userName
    this.userInfo = this.$store.state.userInfo
    this.getOrderCount()
    this.getservicePhone()
  }
}
</script>
<style lang="scss" scoped>
.user-info-wrap{
  display: flex;
  justify-content: space-between;
  height: 1rem;
  padding: .2rem .1rem;
  background:url(../../images/my_bg.png) no-repeat 0 0;
  background-size: 100% 100%;
  color:#fff;
  .user-info{
    display: flex;
    align-items: center;
    width: 100%;
    height: .45rem;
  }
  .user-avatar{
    width: .45rem;
    height: .45rem;
    border-radius: 50%;
  }
  .user-info-txt{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 .08rem;
    font-size: .12rem;
    .username{
      padding-bottom: .08rem;
      font-weight: bold;
      font-size: .14rem;
    }
  }
  .setting-btn{
    width: .2rem;
    height: .19rem;
  }
}
.my-cnt{margin-top: -.6rem}
.wrap{background:#fff; margin: 0 .1rem .1rem; padding:.15rem 0 0; border-radius: .1rem;}
.title{
  display: flex;
  justify-content: space-between;
  padding: .02rem .15rem .15rem;
  h4{font-size: .15rem}
  span{color:#999; font-size: .12rem}
}
.order-wrap{
  padding-bottom: .15rem;
}
.order-wrap ul{
  display: flex;
  padding: 0 .3rem;
  justify-content: space-between;
  li{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    img {
      width: .26rem;
      height: .25rem;
      margin-bottom: .05rem;
    }
    span{
      font-size: .13rem;
      color:#555
    }
    .order-num{
      min-width: .3rem;
      min-height: .3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f00;
      border-radius: 50%;
      color:#fff;
      position: absolute;
      right: -0.1rem;
      top: -0.1rem;
      font-size: .2rem;
      transform: scale(0.5);
    }
  }
}
.service-wrap ul{
  display: flex;
  flex-wrap: wrap;
  padding: 0 .02rem;
  li,.li{
    display: flex;
    flex-direction: column;
    width: 25%;
    align-items: center;
    margin-bottom: .22rem;
    img {
      width: .26rem;
      height: .25rem;
      margin-bottom: .1rem;
    }
    span{
      font-size: .13rem;
      color:#555
    }
  }
}
</style>
