<template>
    <div class="wrapper">
        <div class="address-wrap">
            <div class="address-list" v-for="(item, index) in addressList" :key="item.id">
                <div class="address-info"  @click="checkDetailClick(item)">
                    <div class="address-info-t">
                        <p><span>{{item.consignee}}</span><span>{{item.tel}}</span></p>
                    </div>
                    <div class="address-info-b">
                        <p>{{item.provinceName + item.cityName + item.districtName + item.address}}</p>
                    </div>
                </div>
                <div class="address-manager">
                    <div class="address-manager-l" @click="setDefaultClick(item)" v-if="item.defaultFlag === '1'">
                        <img src="../../images/radio-on.png"/>
                        <span>默认地址</span>
                    </div>
                    <div class="address-manager-l" @click="setDefaultClick(item)" v-else>
                        <img src="../../images/radion.png"/>
                        <span>设为默认</span>
                    </div>
                    <div class="address-manager-r">
                        <img @click="editClick(item)" src="../../images/dz_bj@2x.png"/>
                        <img @click="deleteClick(item,index)" src="../../images/dz_sc@2x.png"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="contentno" v-if="addressList.length<=0">
          <span>您还未增加送货地址，请先增加一个地址</span>
        </div>
    </div>
    <div class="fixed-bom"  @click="toaddaddress">新增</div>
    <Toast v-if="show" :message="toastMessage"/>
</template>
<script>
import qs from 'qs'
import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { post, ajaxParam } from '../../units/request.js'
import Toast, { useToastEffect } from '../../components/Toast.vue'
import { getCookie } from '../../units/tools.js'
export default {
  name: 'Address',
  components: { Toast },
  setup () {
    const data = reactive({ addressList: [], pageNum: 1, addressType: 1 })
    const { show, toastMessage, showToast } = useToastEffect()
    const store = useStore()
    const router = useRouter()
    const getAddressList = async () => {
      // addressType 0为可编辑删除，1为不可编辑删除，为选择地址
      const param = {
        pageNum: data.pageNum,
        userId: getCookie('userId'),
        status: data.addressType
      }
      ajaxParam(param)
      const res = await post('v1/userAddress/list.json', qs.stringify(param))
      if (res.data.code === '200') {
        data.addressList = res.data.list
      }
    }
    // 设置默认地址
    const setDefaultClick = async (model) => {
      const param = {
        addressId: model.id,
        userId: getCookie('userId')
      }
      ajaxParam(param)
      const res = await post('v1/userAddress/setDefaultAddress.json', qs.stringify(param))
      if (res.data.code === '200') {
        for (var i = 0; i < data.addressList.length; i++) {
          var temp = data.addressList[i]
          if (temp.id === model.id) {
            temp.defaultFlag = temp.defaultFlag === '1' ? '0' : '1'
            store.commit('selectAddresssSend', temp)
          } else {
            temp.defaultFlag = '0'
          }
        }
      }
    }
    getAddressList()
    const checkDetailClick = (item) => {
      if (data.addressType === 1) {
        store.commit('selectAddresssSend', item)
        showToast('信息发生变更，请确认')
        setTimeout(res => {
          router.back()
        }, 1000)
      }
    }
    const toaddaddress = () => {
      router.push('/addAddress')
    }
    const deleteClick = async (item, index) => {
      const param = {
        addressId: item.id,
        userId: getCookie('userId')
      }
      ajaxParam(param)
      const res = await post('v1/userAddress/delete.json', qs.stringify(param))
      if (res.data.code === '200') {
        showToast('删除成功')
        const tempList = data.addressList
        tempList.splice(index, 1)
        if (item.id === store.state.addressModel.id) {
          store.commit('selectAddresssSend', {})
          localStorage.removeItem('addressModel')
        }
        // data.addressList = tempList.splice(index, 1)
      }
      console.log(res)
    }
    const editClick = (item) => {
      router.push({
        name: 'AddAddress',
        params: {
          name: item.consignee,
          phone: item.tel,
          address: item.address,
          addressId: item.id,
          defaultFlag: item.defaultFlag,
          provinceId: item.provinceId,
          cityId: item.cityId,
          districtId: item.districtId,
          provinceName: item.provinceName,
          cityName: item.cityName,
          districtName: item.districtName
        }
      })
    }
    const { addressList } = toRefs(data)
    return {
      addressList, show, toastMessage, setDefaultClick, checkDetailClick, toaddaddress, deleteClick, editClick
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper{
    position: absolute;
    left: 0;
    right: 0;
    bottom: .6rem;
    top: 0;
    overflow-y: auto;
    .address-list{
        background:#fff;
        padding:0 .15rem;
        margin-bottom: .1rem;
        .address-info{
            border-bottom: 1px solid #ebebeb;
            padding-top: .15rem;
            padding-bottom: .1rem;
            .address-info-t{
                display: flex;
                justify-content: space-between;
                align-items: center;
                p{
                    line-height: .3rem;
                    font-size: .16rem;
                    span{
                        margin-right: .1rem
                    }
                }
                .check-status{
                    color:#2ac560;
                    font-size: .13rem;
                }
            }
            .address-info-b{
                line-height: .26rem;
                color:#999
            }
        }
        .address-manager{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: .23rem;
            line-height: .23rem;
            padding: .12rem 0;
            .address-manager-l{
                display: flex;
                align-items: center;
                img{
                    width: .18rem;
                    height: .18rem;
                    margin-right: .1rem
                }
                span{
                    color:#666
                }
            }
            .address-manager-r{
                img {
                    width:.57rem;
                    height: .23rem;
                    margin-top: .05rem;
                    margin-left: .15rem;
                }
            }
        }
    }
}
.fix-bom{
    position: fixed;
    left:0;
    right:0;
    bottom: 0;
    background:#fff;
    border-top: 1px solid #ebebeb;
    .add-btn {
        display: block;
        margin: .15rem;
        background:#2ac560;
        text-align: center;
        line-height: .3rem;
        border-radius: .05rem;
        color:#fff
    }
}
.contentno{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background:#fff;
  text-align: center;
}
.contentno span {
  position: relative;
  top: 40%;
  font-size: .16rem;
}
</style>
