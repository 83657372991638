<template>
    <div class="container">
        <div class="swiper-cnt">
          <van-list v-if="noticeList.length > 0"
              v-model:loading="loading"
              :finished="finished"
              :immediate-check = "false"
              finished-text="没有更多了"
              @load="pageLoad"
            >
             <div class="newslist" @click="$router.push('/newsDetail/'+item.id)" v-for="item in noticeList" :key="item.id">
                <h4>{{item.title}}</h4>
                <p>{{item.addTime}}</p>
            </div>
        </van-list>
        <ContentNo :contentTips="contentTips" v-if="noticeList.length <=0"/>
      </div>
    </div>
</template>
<script>
import qs from 'qs'
import { post, ajaxParam, config, formatTime } from '../../units/request.js'
import ContentNo from '../../components/ContentNo.vue'
export default {
  data () {
    return {
      noticeList: [],
      pageNum: 1,
      pageSize: 15,
      isLastPage: 0,
      finished: false,
      loading: false,
      contentTips: '暂无新闻'
    }
  },
  components: {
    ContentNo
  },
  methods: {
    // 获取notice
    async getDataList () {
      const url = 'v1/notice/list'
      const param = {
        cityId: config.cityId
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      console.log(res)
      // this.noticeList = res.data.list
      const list = res.data.list
      list.forEach(item => {
        const myDate = new Date(item.addTime)
        item.addTime = formatTime(myDate)
      })
      this.noticeList.push(...list)
      this.isLastPage = res.data.isLastPage
      if (this.isLastPage === 1) {
        this.finished = true
      }
      // 获取完数据把Loading状态改成false
      this.loading = false
    },
    // 分页
    pageLoad () {
      this.loading = true
      this.pageNum++
      this.getDataList()
    }
  },
  created () {
    this.getDataList()
  }
}
</script>

<style lang="scss" scoped>
.container{
    background:#fff;
    padding: .1rem 0;
    .newslist{
        display:flex;
        flex-direction: column;
        padding: .1rem;
        border-bottom:1px solid #f3f3f3;
        h4{padding-bottom: .08rem;}
        p{color:#999; font-size: .12rem;}
    }
    .swiper-cnt{
        padding: .1rem
    }
}
</style>
