<template>
    <div class="wrapper">
       <router-link to="/city">
            <div class="wrapper_input wrapper-city">
                <div class="wrapper_input-l">
                    <label class="form-list-icon">所在地区</label>
                    <span class="wrapper_content" v-if="JSON.stringify(selectCity) === '{}'">请选择收货地址</span>
                    <span class="wrapper_content" v-else>{{address}}</span>
                </div>
                <img src="../../images/right_arrow.png"/>
            </div>
        </router-link>
       <div class="wrapper_input">
          <label class="form-list-icon">收货人姓名</label>
          <input v-model="name" class="wrapper_content" type="text" placeholder="请输入收货人姓名" />
       </div>
       <div class="wrapper_input">
          <label class="form-list-icon">联系方式</label>
          <input v-model="phone" class="wrapper_content" type="text" placeholder="请输入收货人联系方式" />
       </div>
       <div class="wrapper_input">
          <label class="form-list-icon">详细地址</label>
          <input v-model="addressDetail" class="wrapper_content" type="text" placeholder="街道，楼牌号" />
       </div>
    </div>
    <div class="address-manager" @click="setDefaultClick(item)">
        <img v-if="isDefaultBool === '0'" src="../../images/radion.png"/>
        <img v-if="isDefaultBool === '1'" src="../../images/radio-on.png"/>
        <span>设为默认</span>
    </div>
    <span class="add-btn" @click="tosaveaddress">保存</span>
    <Toast v-if="show" :message="toastMessage"/>
</template>
<script>
// import qs from 'qs'
import { reactive, toRefs, onMounted } from 'vue'
import { post } from '../../units/request.js'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Toast } from 'vant'
// import Toast, { useToastEffect } from '../../components/Toast.vue'
import { getCookie } from '../../units/tools.js'
export default {
  name: 'AddAddress',
  components: { Toast },
  setup () {
    const data = reactive({
      name: '', phone: '', addressDetail: '', isDefaultBool: '1', address: '', addressId: '', provinceId: 0, cityId: 0, countryId: 0
    })
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    // const { show, toastMessage, showToast } = useToastEffect()
    const { selectCity } = toRefs(store.state)
    onMounted(() => {
      const params = route.params
      if (JSON.stringify(params) !== '{}') {
        data.name = params.name
        data.phone = params.phone
        data.addressDetail = params.address
        data.isDefaultBool = params.defaultFlag
        data.addressId = params.addressId
      } else {
      }
      if (JSON.stringify(selectCity.value) !== '{}') {
        data.address = selectCity.value.provinceName + selectCity.value.cityName + selectCity.value.countryName
        data.provinceId = selectCity.value.provinceId
        data.cityId = selectCity.value.cityId
        data.countryId = selectCity.value.countryId
      }
    })
    const tosaveaddress = async () => {
      if (data.address.length <= 0) {
        Toast('请选择地区')
        return false
      }
      if (data.name.length <= 0) {
        Toast('请输入收货人姓名')
        return false
      }
      if (data.phone.length <= 0) {
        Toast('请输入手机号')
        return
      }
      var regNum = new RegExp('^1[3-9][0-9]{9}$')
      if (!regNum.test(data.phone)) {
        Toast('请输入正确的手机号')
        return
      }
      if (data.addressDetail.length <= 0) {
        Toast('请输入详细地址')
        return false
      }
      const param = {
        userId: getCookie('userId'),
        address: data.addressDetail,
        consignee: data.name,
        tel: data.phone,
        provinceId: data.provinceId,
        cityId: data.cityId,
        districtId: data.countryId,
        defaultFlag: data.isDefaultBool,
        addressId: data.addressId,
        lng: '',
        lat: '',
        isJson: true
      }
      // ajaxParam(param)
      const res = await post('v1/userAddress/saveAddress.json', param)
      if (res.data.code === '200') {
        Toast('保存成功！')
        setTimeout(res => {
          router.back()
        }, 1000)
      }
    }
    const setDefaultClick = () => {
      data.isDefaultBool = data.isDefaultBool === '0' ? '1' : '0'
    }
    /* onActivated(() => {
      if (JSON.stringify(selectCity.value) !== '{}') {
        data.address = selectCity.value.provinceName + selectCity.value.cityName + selectCity.value.countryName
        data.provinceId = selectCity.value.provinceId
        data.cityId = selectCity.value.cityId
        data.countryId = selectCity.value.countryId
      }
    })
    onDeactivated(() => {
      data.address = '1'
      data.provinceId = ''
      data.cityId = ''
      data.countryId = ''
      data.address = ''
      selectCity.value = {}
      store.commit('saveSelectCity', {})
    }) */
    const { name, phone, addressDetail, isDefaultBool, address } = toRefs(data)
    return {
      name, phone, addressDetail, isDefaultBool, address, selectCity, tosaveaddress, setDefaultClick
    }
  }
}
</script>

<style scoped lang = "scss">
.wrapper{
    background:#fff;
}
.wrapper_input{
    height: 0.68rem;
    box-sizing: border-box;
    margin: 0 .2rem ;
    border-bottom: 1px solid rgba(0,0,0,.1);
    position: relative;
    .form-list-icon{
        display: block;
        width: 1rem;
        height: .68rem;
        line-height: .68rem;
        position: absolute;
        left:0;
        top: 0;
        font-size: .16rem;
    }
}
.wrapper_content{
    position:absolute;
    left: 1rem;
    right: .2rem;
    line-height: .68rem;
    border:none;
    outline: none;
    background:none;
    font-size: .16rem;
    color: #777;
    &::placeholder {
        color: #777;
    }
}
.address-manager {
    display: flex;
    margin-top: .15rem;
    margin-right: .15rem;
    justify-content: flex-end;
    align-items: center;
    img{
        width: .18rem;
        height: .18rem;
        margin-right: .1rem
    }
    span{
        color:#666
    }
}
.add-btn {
    display: block;
    margin: .15rem;
    background:#2ac560;
    text-align: center;
    line-height: .4rem;
    border-radius: .05rem;
    color:#fff
}
.wrapper-city{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.wrapper-city img{
    width: 0.12rem;
    height: 0.18rem;
    margin-right: 0.15rem;
}
.wrapper_input-l{
    display: flex;
    align-items: center;
}
</style>
