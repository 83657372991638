<template>
    <div class="container">
      <van-list v-if="dataList.length > 0"
              v-model:loading="loading"
              :finished="finished"
              :immediate-check = "false"
              finished-text="没有更多了"
              @load="pageLoad"
            >
              <CouponsList v-for="item in dataList" :key="item.id" :couponItem="item" :type="type" @updateCoupons = "updateCoupons"/>
      </van-list>
      <ContentNo v-if="dataList.length <= 0"/>
    </div>
</template>
<script>
import qs from 'qs'
import { post, ajaxParam, config, formatTimeDate } from '../../units/request.js'
import CouponsList from '../../components/CouponsList.vue'
import ContentNo from '../../components/ContentNo.vue'
export default {
  data () {
    return {
      dataList: [],
      type: '0', // 0领券中心  1我的优惠券
      pageNum: 1,
      pageSize: 6,
      isLastPage: 0,
      finished: false,
      loading: false
    }
  },
  components: {
    CouponsList, ContentNo
  },
  methods: {
    // 获取优惠券列表
    async getDataList () {
      var url = ''
      if (this.type === '0') {
        url = 'v1/coupons/shopCouponsList.json'
      } else {
        url = 'v1/coupons/myCouponsList.json'
      }
      var param = {
        userId: localStorage.userId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        cityId: config.cityId
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      const list = res.data.list
      list.forEach(item => {
        const myDateBegin = new Date(item.useBeginTime)
        const myDateEnd = new Date(item.useEndTime)
        item.useBeginTime = formatTimeDate(myDateBegin)
        item.useEndTime = formatTimeDate(myDateEnd)
      })
      // this.dataList = list
      this.dataList.push(...list)
      this.isLastPage = res.data.isLastPage
      if (this.isLastPage === 1) {
        this.finished = true
      }
      // 获取完数据把Loading状态改成false
      this.loading = false
    },
    // 分页
    pageLoad () {
      this.loading = true
      this.pageNum++
      this.getDataList()
    },
    // 领取按钮自定义事件
    updateCoupons () {
      this.dataList = []
      this.pageNum = 1
      this.loading = false
      this.isLastPage = 0
      this.finished = false
      this.getDataList()
    }
  },
  created () {
    this.type = this.$route.query.type
    this.getDataList()
  }
}
</script>

<style lang="scss" scoped>

</style>
