<template>
    <div class="coupons-list">
        <div class="coupons-list-l">
            <div class="coupons-amount">￥<span>{{couponItem.amount}}</span></div>
            <div class="coupons-use" v-if="couponItem.usedCondition==0">满{{couponItem.orderAmount}}元减</div>
        </div>
        <div class="coupons-list-info">
            <h4>{{couponItem.title}}</h4>
            <div class="coupons-tags">
                <span v-if="couponItem.usedCondition==0 && couponItem.status==1">店铺满减券</span>
                <span v-if="couponItem.usedCondition==0 && couponItem.status==2">商品满减券</span>
                <span>{{couponItem.shopName}}</span>
            </div>
            <div class="coupons-time">
                <text class="iconfont icon-shijian"></text>
                <span>{{couponItem.useBeginTime}}</span>
                <span class="space">至</span>
                <span>{{couponItem.useEndTime}}</span>
            </div>
        </div>
        <div class="coupons-list-r">
            <span class="coupons-day">仅剩{{couponItem.dayNum}}天</span>
            <span class="coupons-btn" v-if="type=='0'&&couponItem.isGet=='1'" @click="checkedCoupons(couponItem.id)">领取</span>
            <span class="coupons-btn coupons-btn-disabled" v-if="type=='0'&&couponItem.isGet=='0'">已领取</span>
            <span class="coupons-btn" v-if="type=='1'"  @click="$router.replace({ name: 'HomeNew' })">去使用</span>
         </div>
    </div>
</template>
<script>
import qs from 'qs'
import { post, ajaxParam } from '../units/request.js'
export default {
  props: ['couponItem', 'type'],
  data () {
    return {
    // usedCondition  0 满减券  1无条件使用
    }
  },
  methods: {
    // 领取优惠券
    async checkedCoupons (id) {
      var url = 'v1/coupons/addCouponsUser.json'
      var param = {
        userId: localStorage.userId,
        couponsId: id
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      console.log(res)
      this.$toast('领取成功')
      this.$emit('updateCoupons')
    }
  }
}
</script>
<style lang="scss" scoped>
.coupons-list{
    background:#fff;
    margin: .1rem .1rem 0;
    border-radius: .05rem;
    display: flex;
    align-items: center;
    height: .9rem;
    font-size: .12rem;
    .coupons-list-l{
        width:.8rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        color:#f46c18;
        .coupons-amount{
            span{
                font-weight: bold;
                font-size: .16rem;
            }
        }
        .coupons-use{
            margin-top: .1rem
        }
    }
    .coupons-list-info{
        flex:1;
        display: flex;
        flex-direction: column;
        border-left: 1px dashed #ddd;
        padding-left: .1rem;
        h4{
            font-size: .14rem;
        }
        .coupons-tags{
            display: flex;
            padding: .1rem 0;
            span{
                margin-right: .05rem;
                padding: .01rem .07rem;
                border-radius: .1rem;
                background:rgba(244,108,24,.1);
                color:#f46c18;
                font-size: .12rem;
            }
        }
        .coupons-time{
            display: flex;
            align-items: center;
            color:#999;
            font-size: .12rem;
            .iconfont{
                margin-right: .05rem
            }
            .space{
                padding: 0 .03rem;
            }
        }
    }
    .coupons-list-r{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        height: .62rem;
        margin-right: .1rem;
        color:#f46c18;
        .coupons-btn{
            width:.45rem;
            height: .22rem;
            background:#f46c18;
            border-radius: .2rem;
            text-align: center;
            line-height: .22rem;
            color:#fff
        }
        .coupons-btn-disabled{
            background:#ccc;
        }
    }
}
</style>
