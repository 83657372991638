<template>
    <div class="container">
        <!-- <SwiperNav :navList="navList"/> -->
        <van-tabs v-model:active="currentTab" @change="onClickTab" swipeable color="#2ac560">
            <van-tab title="常见问题">
                <div class="swiper-cnt">
                  <van-list v-if="dataList.length > 0"
                      v-model:loading="loading"
                      :finished="finished"
                      :immediate-check = "false"
                      finished-text="没有更多了"
                      @load="pageLoad"
                    >
                    <div class="help-list" @click="$router.push({path: '/helpDetail', query:{id: item.id}})" v-for="item in dataList" :key="item.id">
                        <span>{{item.title}}</span>
                        <img src="../../images/right_arrow.png"/>
                    </div>
                </van-list>
                <ContentNo v-if="dataList.length <=0"/>
                </div>
            </van-tab>
            <van-tab title="使用技巧">
              <div class="swiper-cnt">
                    <van-list v-if="dataList.length > 0"
                      v-model:loading="loading"
                      :finished="finished"
                      :immediate-check = "false"
                      finished-text="没有更多了"
                      @load="pageLoad"
                    >
                      <div class="help-list" @click="$router.push({path: '/helpDetail', query:{id: item.id}})" v-for="item in dataList" :key="item.id">
                          <span>{{item.title}}</span>
                          <img src="../../images/right_arrow.png"/>
                      </div>
                  </van-list>
                  <ContentNo v-if="dataList.length <=0"/>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>
<script>
import qs from 'qs'
import { post, ajaxParam } from '../../units/request.js'
import ContentNo from '../../components/ContentNo.vue'
// import SwiperNav from '../../components/SwiperNav.vue'
export default {
  data () {
    return {
      navList: ['常见问题', '使用技巧'],
      currentTab: 0,
      dataList: [],
      pageNum: 1,
      pageSize: 18,
      isLastPage: 0,
      finished: false,
      loading: false
    }
  },
  components: {
    ContentNo
  },
  methods: {
    // 切换选项卡
    onClickTab (e) {
      this.currentTab = e
      this.dataList = []
      this.pageNum = 1
      this.loading = false
      this.finished = false
      this.isLastPage = 0
      this.getDataList()
    },
    // 获取列表
    async getDataList () {
      var url = 'v1/help/helpList.json'
      var catIds
      if (this.currentTab === 0) {
        catIds = '3,31'
      }
      if (this.currentTab === 1) {
        catIds = '3,3'
      }
      var param = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        catIds: catIds
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      // this.dataList = res.data.list
      this.dataList.push(...res.data.list)
      this.isLastPage = res.data.isLastPage
      if (this.isLastPage === 1) {
        this.finished = true
      }
      // 获取完数据把Loading状态改成false
      this.loading = false
    },
    // 分页
    pageLoad () {
      this.loading = true
      this.pageNum++
      this.getDataList()
    }
  },
  created () {
    this.getDataList()
  }
}
</script>

<style lang="scss" scoped>
.container{
    background:#fff;
    padding: .1rem 0;
    .help-list{
        display:flex;
        justify-content: space-between;
        align-items: center;
        padding: .15rem;
        border-bottom:1px solid #f3f3f3;
        img{
            width: .08rem;
            height: .12rem;
        }
    }
    .swiper-cnt{
        padding-top: .1rem
    }
}
</style>
