<template>
    <div class="container">
        <div class="form-item">
            <span class="form-label">旧密码</span>
            <input class="inputtext" type="password" @blur="inputListen('oldPassword', $event)" v-model="oldPassword" placeholder="请输入旧密码"/>
        </div>
        <div class="form-item">
            <span class="form-label">新密码</span>
            <input class="inputtext" type="password" @blur="inputListen('newPassword1', $event)" v-model="newPassword1" placeholder="请输入新密码(长度6-12位)"/>
        </div>
        <div class="form-item">
            <span class="form-label">重复新密码</span>
            <input class="inputtext" type="password" @blur="inputListen('newPassword2', $event)" v-model="newPassword2" placeholder="请再输入一次"/>
        </div>
        <div class="fixed-bom" @click="toCommit">确 认</div>
    </div>
</template>
<script>
import qs from 'qs'
import { post, ajaxParam } from '../../units/request.js'
import { removeCookie } from '../../units/tools.js'
export default {
  data () {
    return {
      oldPassword: '',
      newPassword1: '',
      newPassword2: ''
    }
  },
  methods: {
    inputListen (password, e) {
      this.password = e.target.value
    },
    async toCommit () {
      if (this.oldPassword.length === 0) {
        this.$toast('请输入旧密码')
        return false
      }
      if (this.newPassword1.length === 0) {
        this.$toast('请输入新密码')
        return false
      }
      if (this.newPassword1.length < 6 || this.newPassword1.length > 12) {
        this.$toast('密码长度不合法，至少6-12个字符')
        return false
      }
      if (this.newPassword1 === this.oldPassword) {
        this.$toast('新密码不能与旧密码相同')
        return false
      }
      if (this.newPassword2.length === 0) {
        this.$toast('请输入重复新密码')
        return false
      }
      if (this.newPassword2 !== this.newPassword1) {
        this.$toast('重复新密码与新密码不一致')
        return false
      }
      var url = 'v1/user/changePassword.json'
      var param = {
        userId: localStorage.userId,
        oldPassword: this.oldPassword,
        password: this.newPassword1
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      console.log(res)
      this.$toast(res.data.msg)
      if (res.data.code === '200') {
        setTimeout(res => {
          this.loginout()
        }, 300)
      }
    },
    loginout () {
      removeCookie('isLogin')
      removeCookie('userId')
      removeCookie('username')
      removeCookie('openId')
      removeCookie('nickName')
      localStorage.removeItem('cartList')
      localStorage.removeItem('originAddress')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('carNumNew')
      localStorage.removeItem('pickUpModel')
      localStorage.removeItem('addressModel')
      localStorage.removeItem('payCarList')
      this.$router.replace({ name: 'Login' })
      // location.replace(window.location.href.split('#')[0])
    }
  }
}
</script>
<style lang="scss" scoped>
.container{
    background:#fff;
    position: absolute;
    left:0;
    right:0;
    bottom: 0;
    top: 0;
    .form-item{
        display: flex;
        align-items: center;
        padding: .15rem .1rem;
        border-bottom: 1px solid #f3f3f3;
        .form-label{
            width: 1rem;
            padding-right:.15rem;
            text-align: right;
        }
        .inputtext{
            flex:1;
            height: .2rem;
            border:none
        }
        .inputtext:focus{
            outline: none;
        }
    }
}
</style>
