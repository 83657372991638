<template>
    <div class="container">
        <h3>{{detailModel.title}}</h3>
        <div class="content">
            {{detailModel.content}}
        </div>
    </div>
</template>
<script>
import qs from 'qs'
import { post, ajaxParam } from '../../units/request.js'
export default {
  data () {
    return {
      detailModel: {}
    }
  },
  methods: {
    // 获取notice
    async getDetailModel () {
      const url = 'v1/notice/getDetail'
      const param = {
        noticeId: this.$route.params.id
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      this.detailModel = res.data.entity
    }
  },
  created () {
    this.getDetailModel()
  }
}
</script>
<style lang="scss" scoped>
.container{
    background:#fff;
    position: absolute;
    left:0;
    right:0;
    bottom: 0;
    top: 0;
    overflow: auto;
    padding: .15rem;
    h3{
        padding-bottom: .1rem;
        font-size: .15rem;
        text-align: center;
    }
    .content{
        line-height: .2rem;
        font-size: .13rem;
        p{
            margin-bottom: .05rem;
        }
    }
}
</style>
