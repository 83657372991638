<template>
    <div class="container">
        <!-- <SwiperNav :navList="navList"/> -->
        <div class="swiper-cnt">
            <van-list v-if="dataList.length > 0"
              v-model:loading="loading"
              :finished="finished"
              :immediate-check = "false"
              finished-text="没有更多了"
              @load="pageLoad"
            >
              <GoodsItemOne v-for="(item,index) in dataList" :key="item.id" :dataItem="item" :index="index" @cancelCollect="cancelCollect"/>
           </van-list>
           <ContentNo v-else/>
        </div>
    </div>
</template>
<script>
import qs from 'qs'
import { post, ajaxParam } from '../../units/request.js'
// import SwiperNav from '../../components/SwiperNav.vue'
import GoodsItemOne from '../../components/GoodsItemOne.vue'
import ContentNo from '../../components/ContentNo.vue'
// import ShopItem from '../../components/ShopItem.vue'
export default {
  data () {
    return {
      navList: ['店铺收藏', '商铺收藏'],
      dataList: [],
      pageNum: 1,
      pageSize: 10,
      isLastPage: 0,
      finished: false,
      loading: false
    }
  },
  components: {
    // SwiperNav,
    GoodsItemOne,
    ContentNo
    // ShopItem
  },
  methods: {
    // 获取列表
    async getDataList () {
      var url = 'v1/goods/user/myGoodsCollect.json'
      var param = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        userId: localStorage.userId,
        addUserId: localStorage.userId
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      this.dataList.push(...res.data.list)
      this.isLastPage = res.data.isLastPage
      if (this.isLastPage === 1) {
        this.finished = true
      }
      // 获取完数据把Loading状态改成false
      this.loading = false
    },
    // 分页
    pageLoad () {
      this.loading = true
      this.pageNum++
      this.getDataList()
    },
    // 收藏 取消收藏
    async saveCollect (model) {
      var url = 'v1/goodsCollect/saveCollect.json'
      var param = {
        shopId: model.shopId,
        userId: localStorage.userId,
        goodsId: model.id
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      console.log(res)
      this.dataList.forEach((item, index) => {
        if (item.id === model.id) {
          item.isCollect = !item.isCollect
          this.dataList.splice(index, 1)
        }
      })
    },
    // 取消收藏
    cancelCollect (model) {
      this.saveCollect(model)
    }
  },
  created () {
    this.getDataList()
  }
}
</script>

<style lang="scss" scoped>
.container{
    padding: 0 .15rem .1rem;
    position: absolute;
    left:0; right:0; top:0; bottom:0;
    background:#fff;
    overflow: auto;
}
</style>
