<template>
    <div class="wrapper">
        <div class="citylist"  v-for="item in cityList" :key="item.id" @click="selectModelClick(item)">
            <span>{{item.keyName}}</span>
        </div>
    </div>
</template>
<script>
import qs from 'qs'
import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { post, ajaxParam } from '../../units/request.js'
export default {
  setup () {
    const data = reactive({ cityList: [], provinceId: '', provinceName: '', cityId: '', cityName: '', countryId: '', countryName: '' })
    const store = useStore()
    const router = useRouter()
    const getListData = async () => {
      const param = {}
      ajaxParam(param)
      const res = await post('v1/region/selectCity.json', qs.stringify(param))
      if (res.data.code === '200') {
        data.cityList = res.data.list
      }
    }
    getListData()
    const selectModelClick = (item) => {
      if (data.provinceId === '') {
        data.provinceId = item.id
        data.provinceName = item.keyName
        getCityListData(item.id)
      } else if (data.cityId === '') {
        data.cityId = item.id
        data.cityName = item.keyName
        getCityListData(item.id)
      } else {
        data.countryId = item.id
        data.countryName = item.keyName
        const selectCity = {
          provinceId: data.provinceId,
          provinceName: data.provinceName,
          cityId: data.cityId,
          cityName: data.cityName,
          countryId: data.countryId,
          countryName: data.countryName
        }
        // 保存选择城市
        store.commit('saveSelectCity', selectCity)
        router.back(-1)
      }
    }
    const getCityListData = async (id) => {
      const param = {
        propertyId: id
      }
      ajaxParam(param)
      const res = await post('v1/region/selectCity.json', qs.stringify(param))
      if (res.data.code === '200') {
        data.cityList = res.data.list
        // 没有下级城市返回
        if (data.cityList.length === 0) {
          const selectCity = {
            provinceId: data.provinceId,
            provinceName: data.provinceName,
            cityId: '',
            cityName: '',
            countryId: '',
            countryName: ''
          }
          store.commit('saveSelectCity', selectCity)
          router.back(-1)
        }
      }
    }
    const { cityList } = toRefs(data)
    return {
      cityList, selectModelClick
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper{
    background:#fff
}
.citylist{
    padding: .2rem;
    border-bottom: 1px solid #f0f0f0;
    font-size: .16rem;
}
</style>
