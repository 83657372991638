<template>
    <div class="shop-goods">
        <img class="goods-img" :src="dataItem.photoThumbUrl?dataItem.photoThumbUrl:dataItem.photoUrl?dataItem.photoUrl:require('../images/default_goods.png')"/>
        <div class="goods-info">
            <h4>{{dataItem.goodsName}}</h4>
            <div class="goods-unit">单位：{{dataItem.spec}}</div>
            <div class="price-wrap">
                <span class="price-num">￥{{dataItem.shopPrice}}</span>
                <span class="specs">/{{dataItem.unit}}</span>
            </div>
            <span class="iconfont icon-jushoucang collect-btn" v-if="dataItem.isCollect!=1"></span>
            <span class="iconfont icon-jushoucanggift collect-btn collected" @click="cancelCollect" v-else></span>
        </div>
    </div>
</template>
<script>
export default {
  props: ['dataItem', 'index'],
  methods: {
    cancelCollect () {
      this.$emit('cancelCollect', this.dataItem)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../style/viriables.scss';
.shop-goods{
    display: flex;
    align-items: center;
    position: relative;
    padding:.1rem 0;
    border-bottom: 1px solid #f3f3f3;
    .goods-img{
        width: .8rem;
        height: .8rem;
        margin-right:.1rem;
        border-radius: .05rem;
    }
    .goods-info{
        display: flex;
        flex:1;
        flex-direction: column;
        justify-content: space-between;
        line-height: .24rem;
        .price-wrap{
            font-size: .13rem;
            .price-num{color:#ff371e}
            .specs{color:#666}
        }
        .goods-unit{font-size:.13rem; color:#666}
        .collect-btn{position:absolute; right: .2rem; top: 50%; margin-top: -.15rem}
        .collected{
          color:$green-font
        }
    }
}
</style>
